import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import './App.css';

const querystring = require('querystring');
const q = querystring.parse(window.location.search.substr(1));

const detectStation = function() {
  var station = q.station;
  if (station !== undefined && station.length > 0){
    station = q.station.toLowerCase();
  }
  else {
    station = "ph"
  }
  return station;
}

const detectMovement = function(){
  var movement = q.type;
  if (movement !== undefined && movement.length > 0) {
    movement = q.type.toLowerCase();
  }
  else {
    movement = "departure";
  }
  return movement;
}

const stationCode = detectStation();
const movement = detectMovement();
var movementHeader;
var movementRowHeader;

export default class App extends Component {
  state = {
    stations: [],
    trains: [],
    isLoadingHeader: true,
    isLoadingRows: true,
    errors: null
  }
  getStations() {
    axios
      .get("https://rata.digitraffic.fi/api/v1/metadata/stations")
      .then(response => {
        this.setState({
          stations: response.data,
          isLoadingHeader: false
        });
      })
      .catch(error => this.setState({ error, isLoadingHeader: false}));
  }
  getTrains() {
    axios
      .get("https://rata.digitraffic.fi/api/v1/live-trains/station/" + stationCode + "?minutes_before_departure=1440&minutes_after_departure=5&minutes_before_arrival=1440&minutes_after_arrival=5&include_nonstopping=true")
      .then(response => {
        this.setState({
          trains: response.data,
          isLoadingRows: false,
        });
      })
      .catch(error => this.setState({ error, isLoadingRows: false }));
  }
  getListOfStations(stations) {
    const stationList = [];

    for (var i = 0, len = stations.length; i < len; i++) {
      const station = parsedStations(stations[i]);
      stationList.push(station);

      function parsedStations(item){
        const stationShortCode = item.stationShortCode;
        const stationName = item.stationName;

        const listOfStations = {
          label: stationName,
          value: stationShortCode
        }
        return listOfStations;
      }
    }
    return stationList;
  }
  getHeaders() {
    if(movement === "arrival") {
      movementHeader = "Saapuvat";
      movementRowHeader = "Saapumisaika";
    }
    else {
      movementHeader = "Lähtevät";
      movementRowHeader = "Lähtöaika";
    }
  }
  componentDidMount() {
    this.getStations();
    this.getTrains();
    this.getHeaders()
  }
  parsedTrains(trains){
    const arr = [];
    for (var i = 0, len = trains.length; i < len; i++) {
      const train = parseObjects(trains[i]);
      arr.push(train);
    }

    function parseObjects(item) {
      const trainName = item.trainType + " " + item.trainNumber;
            
      function chooseDirection() {

        function isDeparturing(x) {
          return x.stationShortCode.toLowerCase() === stationCode && x.type.toLowerCase() === "departure";
        }
        function isArriving(x) {
          return x.stationShortCode.toLowerCase() === stationCode && x.type.toLowerCase() === "arrival";
        }
        function isBypassing(x) {
          return x.stationShortCode.toLowerCase() === stationCode;
        }

        var localTrains;
        var primaryFilterKey;
        var secondaryFilterKey;
        switch (movement) {
          case "departure":
            primaryFilterKey = isDeparturing;
            secondaryFilterKey = isArriving;
            break;
          case "arrival":
            primaryFilterKey = isArriving;
            secondaryFilterKey = isDeparturing;
            break;
          default:
            primaryFilterKey = isDeparturing;
            secondaryFilterKey = isArriving;
        }
        if(item.timeTableRows.filter(primaryFilterKey).length === 1) {
          localTrains = item.timeTableRows.filter(function (e) {
            return primaryFilterKey(e);
          });
        }
        else {
          if(item.timeTableRows.filter(secondaryFilterKey).length === 1) {
            localTrains = item.timeTableRows.filter(function (e) {
              return secondaryFilterKey(e);
            });
          }
          else {
            localTrains = item.timeTableRows.filter(function (e) {
              return isBypassing(e);
            });
          }
        }
        return localTrains;
      }

      function getliveEstimateTime(localTrains) {
        var estimate;
        if (localTrains[0].differenceInMinutes !== 0 && localTrains[0].liveEstimateTime !== undefined) {
          estimate = localTrains[0].liveEstimateTime;
        }
        else {
          estimate = "";
        }
        return estimate
      }

      const localTrains = chooseDirection();
      const lastStop = item.timeTableRows[item.timeTableRows.length-1].stationShortCode;
      const trainStopping = localTrains[0].trainStopping;
      const commercialTrack = localTrains[0].commercialTrack;
      const cancelled = localTrains[0].cancelled;
      const scheduledTime = localTrains[0].scheduledTime;
      const liveEstimateTime = getliveEstimateTime(localTrains);
      const differenceInMinutes = localTrains[0].differenceInMinutes;

      const localTrain = {
        trainName: trainName,
        lastStop: lastStop,
        trainStopping: trainStopping,
        commercialTrack: commercialTrack,
        cancelled: cancelled,
        scheduledTime: scheduledTime,
        liveEstimateTime: liveEstimateTime,
        differenceInMinutes: differenceInMinutes
      }
      return localTrain;
    }
    return arr.sort(function(a, b) {var dateA = new Date(a.scheduledTime), dateB = new Date(b.scheduledTime);return dateA - dateB;});
  }
  render(){
    const { isLoadingHeader, isLoadingRows, stations, trains } = this.state;
    const parsedTrains = this.parsedTrains(trains);
    return(
    <React.Fragment>
      {!isLoadingHeader ? (
        stations.map(station => {
        const { stationUICCode, stationName, stationShortCode} = station;
        if (stationShortCode.toLowerCase() === stationCode) {
          return (
          <div key="headerContainer">
            <div className="header" key={stationUICCode}>
              <h2>{stationName} - {movementHeader}</h2>
            </div>
            <div className="row" key="headerRow">
              <div className="col-md-3">{movementRowHeader}</div>
              <div className="col-md-2">Juna</div>
              <div className="col-md-3">Pääteasema</div>
              <div className="col-md-2">Raide</div>
              <div className="col-md-2">Aikataulu (+/-)</div>
            </div>
          </div>
          );
        } else {
          return (null);
        }
        })
      ) : (
        <p className="loading-text">Liikennepaikan tietoja haetaan...</p>
      )}
    
      {!isLoadingRows ? (
        parsedTrains.map(train => {
          const { trainName, trainStopping, lastStop, commercialTrack, cancelled, scheduledTime, liveEstimateTime, differenceInMinutes } = train;
        const estimateTime = this.dateFormatting(liveEstimateTime);
          return (
            <div className="row" key={trainName + "-" + scheduledTime}>
              <div className="col-md-3">{this.dateFormatting(scheduledTime)} {this.formatLiveEstimateTime(estimateTime)}</div>
              <div className="col-md-2">{this.trainNameFormer(trainName,cancelled)}</div>
              <div className="col-md-3">{this.getStationName(stations,lastStop)}</div>
              <div className="col-md-2">{this.trainStopping(trainStopping,commercialTrack)}</div>
              <div className="col-md-2">{this.timeDifferenceFormer(differenceInMinutes)}</div>
            </div>
          );
        })
      ) : (
        <p className="loading-text">Junien tietoja haetaan...</p>
      )}

    </React.Fragment>

    );
  }
  getStationName(stations,code){
    for (var i=0; i < stations.length; i++) {
      if (stations[i].stationShortCode === code) {
        return stations[i].stationName;
      }
    }
  }
  formatLiveEstimateTime(time){
    var output
    if(time.length > 0 && time !== undefined) {
      output = "(~ " + time + ")"
    }
    else {
      output = ""
    }
    return output;
  }
  trainStopping(trainStopping, commercialTrack){
    if(trainStopping === true) {
        const trimmedTrack = commercialTrack.replace(/^0+/, '')
        return(trimmedTrack);
        } else {
        return('-');
        }       
  }
  dateFormatting(date){
    if (date.length > 0 && date !== undefined) {
      moment.locale('fi');
      if (moment(new Date(date)).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
        return moment(new Date(date)).format('HH.mm');
      }
      else {
        return moment(new Date(date)).format('DD.MM.YYYY HH.mm');
      }
    }
    else {
      date = "";
    }
    return date

  }
  trainNameFormer(trainName,cancelled){
        if (cancelled === true) {
      return(<span className='strikethrough'>{trainName}</span>);
    }
    else {
      return(trainName);
    }
  }
  timeDifferenceFormer(differenceInMinutes){
    if (differenceInMinutes > 0) {
      return("+" + differenceInMinutes + " min");
    }
    if (differenceInMinutes < 0) {
      return(differenceInMinutes + " min");
    }
  }
  }

